// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_ID,

  apiKey: 'AIzaSyAoaNV9ZmI0EpHxVoYOnWnxr2K9xD94h3s',
  authDomain: 'chat-application-d7de9.firebaseapp.com',
  projectId: 'chat-application-d7de9',
  storageBucket: 'chat-application-d7de9.appspot.com',
  messagingSenderId: '489689107883',
  appId: '1:489689107883:web:e4ccf59e55717a8ce196ee',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

//---- exports ---
export { auth, db, storage };
