import MyNotiifcation from 'components/notification';
import {
  collection,
  doc,
  getDocs,
  Timestamp,
  updateDoc,
  query,
  orderBy,
  setDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { createContext, useState, useEffect } from 'react';
import { db, storage } from 'services/firebase';

export const SystemContext = createContext();

export const SystemContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const [auth, setAuth] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const [documentMaydon, setDocumentMaydon] = useState(undefined);
  const [documentMaydoniNomi, setDocumentMaydoniNomi] = useState(undefined);

  const [allSendDoc, setAllSendDoc] = useState([]);

  const [comments, setComments] = useState('');
  const [messages, setMessages] = useState('');

  const [message, setMessage] = useState('');
  const [file, setFile] = useState([]);

  const [location, setLocation] = useState('');

  const EditOnlineTime = async () => {
    const user = localStorage?.getItem('user')
      ? JSON?.parse(localStorage?.getItem('user'))
      : null;
    if (user !== null) {
      await updateDoc(doc(db, 'users', user?.id), {
        isOnline: true,
        last_visit: Timestamp.fromDate(new Date()),
      });
    }
  };

  async function ChangeFile(e) {
    setFile([
      {
        url: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      },
    ]);
  }

  async function onSubmit(type) {
    setLoader(true);
    try {
      const user = localStorage?.getItem('user')
        ? JSON?.parse(localStorage?.getItem('user'))
        : null;

      if (type === 'comments') {
        await setDoc(doc(db, 'comments', `${user?.id + Date.now()}`), {
          name: user?.familiya + ' ' + user?.ism,
          imgUrl: user?.imgUrl,
          email: user?.email?.substr(0, user?.email?.indexOf('@')),
          createdAt: Timestamp.fromDate(new Date()),
          message: message,
        });

        GetComments();
      }
      if (type === 'messages') {
        let url;
        const friend = allUser.filter(
          (x) =>
            x.email.indexOf(
              location.substr(`${location.indexOf('@') + 1}`) + '@'
            ) > -1
        );
        if (file?.length > 0) {
          const storageRef = ref(
            storage,
            `${type === 'chat' ? 'messages' : 'comments'}/${user?.id}/${
              file[0]?.file?.name
            }-${Date.now()}`
          );
          const urlPic = await uploadBytes(storageRef, file[0]?.file);
          url = await getDownloadURL(ref(storage, urlPic?.ref?.fullPath));
        }
        await setDoc(doc(db, 'messages', `${user?.id + Date.now()}`), {
          sender: user?.id,
          comer: friend[0]?.id,
          createdAt: Timestamp.fromDate(new Date()),
          message: message,
          fileUrl: file.length > 0 ? url : '',
        });
        GetMessages(location);
      }

      EditOnlineTime();
      setLoader(false);
      setFile([]);
      setMessage('');
      if (type === 'comments') {
        return MyNotiifcation('success', 'Izoh qoldirildi!');
      }
    } catch (error) {
      setLoader(false);
      return MyNotiifcation(
        'error',
        error.code
          ? error.code
          : error.message
          ? error.message
          : "Xatolik yuz berdi, qayta tekshirib ko'ring!"
      );
    }
  }

  async function getAllUsers() {
    // setLoader(true);
    const user = localStorage?.getItem('user')
      ? JSON?.parse(localStorage?.getItem('user'))
      : null;

    try {
      setAllUser([]);
      // const querySnapshot = await getDocs(collection(db, 'users'));
      const q = query(collection(db, 'users'), orderBy('last_visit'));
      const querySnapshot = await getDocs(q);

      let users = [];
      querySnapshot?.forEach((doc) => {
        users.unshift(doc?.data());
      });

      if (user !== null) {
        await updateDoc(doc(db, 'users', user?.id), {
          isOnline: true,
          last_visit: Timestamp.fromDate(new Date()),
        });
      }

      setAllUser(users);
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
    // setLoader(false);
  }

  async function GetSendDoc() {
    try {
      setAllSendDoc([]);
      const q = query(collection(db, 'sendedDoc'));
      const querySnapshot = await getDocs(q);

      let document = [];
      querySnapshot?.forEach((doc) => {
        document.push(doc?.data());
      });
      setAllSendDoc(document);
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }
  async function GetComments() {
    try {
      setComments([]);
      const q = query(collection(db, 'comments'), orderBy('createdAt'));
      const querySnapshot = await getDocs(q);

      let comments = [];
      querySnapshot?.forEach((doc) => {
        comments.push(doc?.data());
      });
      setComments(comments);
      EditOnlineTime();
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }

  async function GetEnterprises() {
    try {
      setEnterprises([]);
      const q = query(collection(db, 'enterprises'), orderBy('value'));
      const querySnapshot = await getDocs(q);

      let ent = [];
      querySnapshot?.forEach((doc) => {
        ent.push(doc?.data());
      });
      setEnterprises(ent);
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }

  async function GetMaydonBelgisi() {
    try {
      setDocumentMaydon(undefined);
      const q = query(collection(db, 'maydonBelgisi'));
      const querySnapshot = await getDocs(q);
      let ent = [];
      querySnapshot?.forEach((doc) => {
        ent.push(doc?.data());
      });
      setDocumentMaydon(ent[0]);
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }

  async function GetMaydonNomi() {
    try {
      setDocumentMaydoniNomi(undefined);
      const q = query(collection(db, 'maydonNomi'));
      const querySnapshot = await getDocs(q);
      let ent = [];
      querySnapshot?.forEach((doc) => {
        ent.push(doc?.data());
      });
      setDocumentMaydoniNomi(ent[0]);
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }

  async function GetMessages(location) {
    const user = localStorage?.getItem('user')
      ? JSON?.parse(localStorage?.getItem('user'))
      : null;

    const friend = allUser.filter(
      (x) =>
        x.email.indexOf(location.substr(`${location.indexOf('@') + 1}`) + '@') >
        -1
    );

    try {
      setMessages([]);
      const q = query(
        collection(db, 'messages'),
        where('comer', 'in', [user?.id, friend[0]?.id]),
        orderBy('createdAt')
      );
      const querySnapshot = await getDocs(q);

      let message = [];
      querySnapshot?.forEach((doc) => {
        if (
          (doc.data()?.sender === user?.id &&
            doc.data()?.comer === friend[0]?.id) ||
          (doc.data()?.comer === user?.id &&
            doc.data()?.sender === friend[0]?.id)
        ) {
          message.push(doc?.data());
        }
      });
      setMessages(message);
      EditOnlineTime();
    } catch (error) {
      console.log(error);
      return MyNotiifcation('error', error.message);
    }
  }

  async function AuthToken() {
    const user = localStorage?.getItem('user')
      ? JSON?.parse(localStorage?.getItem('user'))
      : null;
    if (user !== null) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }
  useEffect(() => {
    GetComments();
    GetSendDoc();
  }, [location]);

  useEffect(() => {
    if (localStorage?.getItem('user') === 'undefined') {
      localStorage.removeItem('user');
    }
    getAllUsers();
    AuthToken();
    GetEnterprises();
    GetMaydonBelgisi();
    GetMaydonNomi();
  }, []);

  return (
    <SystemContext.Provider
      value={{
        loader,
        allUser,
        auth,
        setLoader,
        getAllUsers,
        AuthToken,
        EditOnlineTime,
        onSubmit,
        ChangeFile,
        message,
        setMessage,
        comments,
        messages,
        GetComments,
        setComments,
        setLocation,
        file,
        GetMessages,
        enterprises,
        selectedItem,
        setSelectedItem,
        documentMaydon,
        documentMaydoniNomi,
        allSendDoc,
        GetSendDoc,
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};
