import toast from 'react-hot-toast';

export default function MyNotiifcation(type, text) {
  switch (type) {
    case 'success':
      return toast.success(text ? text : 'Muvaffaqqiyatli yaratildi!');
    case 'error':
      return toast.error(
        text ? text : "Xatolik ro'y berdi, qayta harakat qiling!"
      );
    case 'info':
      return toast(text ? text : "Ma'lumot: Hammasi yaxshi!", {
        icon: 'ℹ️',
      });
    default:
      return toast(
        text ? text : "Amallarda noaniqlik bor, e'tiborli bo'ling!",
        { icon: '⚠️' }
      );
  }
}
