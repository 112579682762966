import { Spin } from 'antd';
import React from 'react';
import { LoaderProvider } from './style';

export default function LoaderComponent() {
  return (
    <LoaderProvider>
      <Spin size="large" />
    </LoaderProvider>
  );
}
