import { customColor } from 'services/style';
import styled from 'styled-components';

export const LoaderProvider = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${customColor.BgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
