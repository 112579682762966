import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import 'react-lazy-load-image-component/src/effects/blur.css';
import i18n from 'services/localization';
import { SystemContextProvider } from './context';
import './style.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SystemContextProvider>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </SystemContextProvider>
  </React.StrictMode>
);
