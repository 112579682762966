import { lazy } from 'react';

const HomePage = lazy(() => import('pages/home'));
const ChatPage = lazy(() => import('pages/chatPage'));
const MyAccountPage = lazy(() => import('pages/editAccount'));
const TempletePage = lazy(() => import('pages/templetes'));
const CreateTemplatePages = lazy(() => import('pages/createTemplate'));
const SendTempletePage = lazy(() => import('pages/sendTemplete'));
const CreateTempDetailPage = lazy(() => import('pages/sendTempleteDetail'));

const LoginComponent = lazy(() => import('pages/login'));
const RegisterComponent = lazy(() => import('pages/register'));
// agar true bo'lsa main pageda bo'lmasa authd bo'ladi
export const myRoutes = [
  {
    private: false,
    element: <LoginComponent />,
    path: '/',
  },
  {
    private: false,
    element: <RegisterComponent />,
    path: '/register',
  },
  {
    private: true,
    element: <HomePage />,
    path: '/chats',
  },
  {
    private: true,
    element: <TempletePage />,
    path: '/templetes',
  },
  {
    private: true,
    element: <CreateTemplatePages />,
    path: '/createTemplate',
  },
  {
    private: true,
    element: <SendTempletePage />,
    path: '/sendTemplate',
  },
  {
    private: true,
    element: <CreateTempDetailPage />,
    path: '/sendTemplate/:id',
  },
  {
    private: true,
    element: <MyAccountPage />,
    path: '/my-profile',
  },
  {
    private: true,
    element: <ChatPage />,
    path: '/chats/:id',
  },
];
