import { sizes } from 'services/style/index';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as Logo } from 'assets/icons/logoWhite.svg';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  position: relative;
  padding: ${sizes.s3};
`;
export const Img = styled(LazyLoadImage)`
  border-radius: ${sizes.s4};
  user-select: none;
  object-fit: cover;
  width: 100%;
`;
export const IconLogo = styled(Logo)`
  max-width: 200px;
  top: 50px;
  right: 60px;
  position: absolute;
  max-height: 100px;
  padding: 0;
  margin: 0;
  @media (max-width: 1400px) {
    top: 36px;
    right: 8px;
    max-height: 70px;
  }
`;

export const WrapLinks = styled.div`
  gap: 12px;
  left: 100px;
  bottom: 80px;
  display: flex;
  position: absolute;
  @media (max-width: 1400px) {
    left: 60px;
    bottom: 36px;
  }
`;
