import { Col } from 'antd';
import { SystemContext } from 'context';
import React, { useEffect, useState, Suspense, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import LeftContent from './leftContent';
import { MyRow, RightContainer } from './style';
import LoaderComponent from 'components/loader';

export default function Auth({ children }) {
  const [mobile, setMobile] = useState(false);
  const { auth } = useContext(SystemContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 786) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (auth === true) {
      navigate('/chats');
    } else {
      navigate('/');
    }
  }, [auth]);

  return (
    <MyRow>
      {mobile === false ? (
        <Col xs={24} md={10}>
          <LeftContent />
        </Col>
      ) : null}

      <Col xs={24} md={14}>
        <RightContainer>
          <Suspense fallback={<LoaderComponent />}>
            <Outlet />
          </Suspense>
        </RightContainer>
      </Col>
    </MyRow>
  );
}
