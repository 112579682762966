import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { myRoutes } from './routes.path';
import Auth from 'pages/auth';

//layouts
const PrivateRoute = lazy(() => import('layout/privateRoute'));
const Routers = () => {
  const { i18n } = useTranslation();
  const language = localStorage.getItem('language');

  useEffect(() => {
    if (language !== null) {
      i18n.changeLanguage(language);
    } else {
      localStorage.setItem('language', 'uz');
    }
  }, [language, i18n]);

  return (
    <>
      <Suspense fallback={null}>
        <Routes>
          <Route element={<PrivateRoute />}>
            {myRoutes.map(
              (item, index) =>
                item.private && (
                  <Route key={index} path={item.path} element={item.element} />
                )
            )}
          </Route>
          <Route element={<Auth />}>
            {myRoutes.map(
              (item, index) =>
                !item.private && (
                  <Route key={index} path={item.path} element={item.element} />
                )
            )}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default Routers;
