import { Row } from 'antd';
import { customColor, sizes } from 'services/style/index';
import styled from 'styled-components';

export const MyRow = styled(Row)`
  overflow: hidden;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${customColor.BgColor} inset !important;
    background-color: transparent !important;
  }

  @media (max-width: 1400px) {
    padding: ${sizes.space2};
  }
`;
export const AuthContainer = styled.div`
  width: 800px;
  text-align: center;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${customColor.greyBgColor} inset !important;
    background-color: transparent !important;
  }

  @media (max-width: 1400px) {
    padding: ${sizes.space2};
  }
  h1 {
    color: ${customColor.sidebarBgColor} !important;
    font-weight: bold;
    margin-bottom: 4px;
  }
  p {
    color: ${customColor.textColor} !important;
  }
  .subtitle {
    font-weight: bold;
  }
  .text-field {
    margin-bottom: 10px;
    padding: 0 8px;
  }
  .loginButton {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    width: 320px;
    &.register-container {
      padding-top: 200px;
    }
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  height: calc(100vh - ${sizes.s5});
  border-radius: ${sizes.s3};
  padding: ${sizes.s3};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 2px;
  }

  @media (max-width: 1400px) {
    height: calc(100vh - ${sizes.s4});
  }

  @media (max-width: 768px) {
    height: 100vh !important;
    overflow-y: auto !important;
  }
`;
