import { useContext } from 'react';
import { SystemContext } from 'context';
import Routers from 'routes';
import 'antd/dist/antd.css';
import { SiteProvider } from 'services/style';
import { Toaster } from 'react-hot-toast';
import { Spin } from 'antd';

function App() {
  const { loader } = useContext(SystemContext);
  return (
    <SiteProvider>
      <Spin size="large" spinning={loader}>
        <Routers />
        <Toaster position="top-right" reverseOrder={true} />
      </Spin>
    </SiteProvider>
  );
}

export default App;
