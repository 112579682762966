import React from 'react';
import { Container, IconLogo, Img, WrapLinks } from './style';
import LoginImage from 'assets/img/webchat.jpg';
import { links } from './constants';

export default function LeftContent() {
  return (
    <Container>
      <Img
        alt="bgImage"
        src={LoginImage}
        height="100%"
        width="100%"
        effect="blur"
      />
      <IconLogo />
      <WrapLinks>
        {links.map(({ link, Icon }) => (
          <a href={link} key={link}>
            <Icon />
          </a>
        ))}
      </WrapLinks>
    </Container>
  );
}
