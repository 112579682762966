import { Card, Modal } from 'antd';
import styled from 'styled-components';

export const device = {
  mobile: '600px',
  planshet: '1000px',
  laptop: '1400px',
  desktop: '1600px',
};

export const sizes = {
  s1: '4px',
  s2: '8px',
  s3: '16px',
  s4: '24px',
  s5: '32px',
  shadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
};

export const customColor = {
  //sidebar Colors
  sidebarBgColor: '#1d2d5b',
  sidebarTextColor: '#e0e3f3',
  sidebarHoverBgColor: '#2d3c69',

  //white Colors
  greyBgColor: '#f5f4fa',

  BgColor: '#ffffff',
  passiveBgColor: '#f3f3f5',
  passiveTextColor: '#777',

  passiveBtnColor: '#d6d7eb',

  primaryColor: '#4848a0',

  textColor: '#444',

  bgRedColor: '#fff2f0',
  redTextColor: 'red',
};

export const FullProvider = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
`;
export const MyModal = styled(Modal)``;

export const Paper = styled(Card)`
  background-color: ${customColor.whiteColor};
  border-radius: ${sizes.s2};
  border: none;
  box-shadow: ${sizes.shadow};
  min-width: 300px;
`;
export const SiteProvider = styled.div`
  background-color: ${customColor.BgColor};
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100vh !important;
  }
  .sign-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: green;
    span {
      font-size: 18px;
      color: green;
      margin-bottom: 0;
    }

    &:hover {
      background-color: green !important;
      border-color: green !important;
      transform: scale(1.05);
      span {
        color: #fff;
      }
    }
  }
  .button-azamov {
    button {
      margin-right: 16px;
      border-radius: ${sizes.s1};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      transition: all 0.3s ease;
      color: #fff !important;
      &:hover {
        transform: scale(1.1);
        color: #fff !important;
      }
    }
    .sign {
      background-color: green !important;
      border-color: green !important;
    }
  }
`;
export const Error = styled.div`
  color: ${customColor.redTextColor};
  border-radius: ${sizes.s1};
  margin-top: ${sizes.s1};
  font-size: 12px;
  padding: 4px 8px;
  text-align: left;
  /* background-color: ${customColor.bgRedColor}; */
`;
